import React from 'react'

const Footer = () => {
    return (
        <div className="footer">
            <p>&copy; 2022 Beyza Madenoğlu </p>
        </div>
    )
}

export default Footer